import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const WayneStrongStoryPage = () => (

    <Layout pageClass="story-page">
    <SEO title="Wayne Strong - EmbroidMe East Tamaki - Story" />
    <main className="main">
        <div className="typography inner">
            <h1>Wayne Strong - EmbroidMe East Tamaki</h1>          
            <p>EmbroidMe East Tamaki have used Experience on Tap staff for a number of different projects over the last 15 years. These include Business Coaching, Special Projects, Planned business growth strategies and Sales Training.</p>

           <p>I’ve always founds Dave and his team to be nothing but completely professional. If you want someone to look at your business from a different perspective and give you the benefit of their many years’ experience, then look no further.</p>

            <p>We highly recommend Experience on Tap.</p>
        </div>
    </main>
  </Layout>

)


export default WayneStrongStoryPage